import { FileTextIcon } from "@radix-ui/react-icons";
import clsx from "clsx";

export function EventsEmpty() {
  return (
    <div
      className={clsx(
        "text-center",
        "py-4",
        "font-bold",
        "text-lg",
        "text-gray-700",
        "flex",
        "items-center",
        "justify-center"
      )}
    >
      <FileTextIcon width="22" height="22" className={clsx("inline-block", "mr-2")} />
      予定されたイベントはありません
    </div>
  );
}
